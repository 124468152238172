import { VStack, Text } from "@chakra-ui/react";

const BodyTagline = () => {
  return (
    <VStack py={[12, null, 20]} px={[8, null, "unset"]} bg={"#1A1B19"}>
      <Text color={"white"} fontSize={["3xl", null, "5xl"]} fontWeight={"bold"}>
        QuiveClub <br />
        UNLOCK THE BUZZ <br />
        EMBRACE THE HIVE
      </Text>
    </VStack>
  );
};
export default BodyTagline;
