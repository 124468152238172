import {
  Box,
  Button,
  VStack,
  Text,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";

const ContactUsForm = () => {
  return (
    <VStack
      id="contact-us"
      py={8}
      bg={"#161616"}
      px={[8, null, 40]}
      spacing={4}
    >
      <VStack spacing={8}>
        <Text fontSize={"xl"} color={"#D64E1C"} fontWeight={"bold"}>
          CONTACT US
        </Text>
        <Text color={"white"} fontSize={"md"} fontWeight={"semi-bold"}>
          REACH OUT, IGNITE POSSIBILITIES.
        </Text>
      </VStack>
      <VStack w={"full"} spacing={8}>
        <FormControl textColor={"white"}>
          <VStack alignItems={"start"} spacing={4}>
            <Box width={"full"}>
              <FormLabel>Name</FormLabel>
              <Input type="text" />
            </Box>
            <Box width={"full"}>
              <FormLabel>Email</FormLabel>
              <Input type="email" />
            </Box>
            <Box width={"full"}>
              <FormLabel>Message</FormLabel>
              <Textarea
                // value={"value"}
                onChange={() => {}}
                size="lg"
                h={40}
              />
            </Box>
          </VStack>
        </FormControl>
        <Box w={"full"}>
          <Button
            textColor={"white"}
            size="lg"
            bg={"#D64E1C"}
            w={["full", null, "32"]}
            fontSize={["sm", null, "md"]}
            _hover={{ bg: "#FF5F1F" }}
          >
            SEND
          </Button>
        </Box>
      </VStack>
    </VStack>
  );
};
export default ContactUsForm;
