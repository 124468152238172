import { ChakraProvider, VStack } from "@chakra-ui/react";
import Header from "../components/Header";
import HeroSection from "../components/heroSection/HeroSection";
import BodyTagline from "../components/BodyTagline";
import ContactUsForm from "../components/ContactUsForm";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <ChakraProvider>
      <VStack
        alignItems={"center"}
        pt={4}
        pb={16}
        spacing={[12, null, 20]}
        bg={"black"}
      >
        <Header />
        <HeroSection />
      </VStack>
      <BodyTagline />
      <ContactUsForm />
      <Footer />
    </ChakraProvider>
  );
};
export default Home;
