import { Box, VStack, Text, HStack } from "@chakra-ui/react";

const CountdownTimer = ({ number, unit }) => {
  const digits = number.toString().split("");
  return (
    <>
      {/* Count Down Timer Web View */}
      <VStack display={["none", null, "flex"]}>
        <HStack spacing={0}>
          {digits.map((digit, index) => {
            return (
              <Box key={index} bg="white" p={4} borderRadius="md" mx={2}>
                <Text fontSize="3xl" fontWeight={"bold"}>
                  {digit}
                </Text>
              </Box>
            );
          })}
        </HStack>
        <Text color={"white"} fontSize={"xs"}>
          {unit}
        </Text>
      </VStack>
      {/* Count Down Timer mobile View */}
      <Box display={["flex", null, "none"]}>
        <Box bg="white" p={2} borderRadius="md" mb={2}>
          <Text fontSize="2xl" fontWeight={"bold"}>
            {number}
          </Text>
          <Text fontSize={"xs"}>{unit}</Text>
        </Box>
      </Box>
    </>
  );
};
export default CountdownTimer;
