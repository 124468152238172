import { Box, Text } from "@chakra-ui/react";
import React from "react";
const Header = () => {
  return (
    <Box w={"full"} pl={["unset", null, 8]}>
      <Text
        color={"#D64E1C"}
        textAlign={["center", null, "start"]}
        fontSize={"4xl"}
        fontWeight={"bold"}
      >
        QuiveClub
      </Text>
    </Box>
  );
};
export default Header;
