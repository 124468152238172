import { Box, Button, VStack, Text, HStack } from "@chakra-ui/react";

import { useState, useEffect } from "react";
import CountdownTimer from "./CountdownTimer";

const HeroSection = () => {
  const targetDate = new Date("2024-04-01T22:00:00");
  const [scrollToContactUs, setScrollToContactUs] = useState(false);
  const calculateTimeLeft = () => {
    const difference = targetDate - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleScrollToContactUs = () => {
    setScrollToContactUs(true);
  };

  useEffect(() => {
    if (scrollToContactUs) {
      const contactUsSection = document.getElementById("contact-us");
      if (contactUsSection) {
        contactUsSection.scrollIntoView({ behavior: "smooth" });
        setScrollToContactUs(false);
      }
    }
  }, [scrollToContactUs]);

  const showTimeLeft = Object.keys(timeLeft).length;

  return (
    <VStack spacing={[8, null, 12]}>
      <Text
        color={"white"}
        fontSize={["3xl", null, "4xl"]}
        fontWeight={["semi-bold", null, "unset"]}
      >
        EDUCATE TO ELEVATE: UNVEILING SOON!
      </Text>
      <Text px={8} color={"white"} fontSize={["xl", null, "2xl"]}>
        The date is near, and the stage is set! Join the waitlist to be a part
        of it!
      </Text>

      {showTimeLeft > 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
        >
          <HStack spacing={[2, null, 8]}>
            <CountdownTimer number={timeLeft.days} unit={"Days"} />
            <CountdownTimer number={timeLeft.hours} unit={"Hours"} />
            <CountdownTimer number={timeLeft.minutes} unit={"Minutes"} />
            <CountdownTimer number={timeLeft.seconds} unit={"Seconds"} />
          </HStack>
        </Box>
      ) : (
        <Text
          color={"white"}
          fontSize={["3xl", null, "4xl"]}
          fontWeight={["semi-bold", null, "unset"]}
        >
          We are Launching Soon...
        </Text>
      )}

      <Box w={["full", null, "unset"]} px={[8, null, "unset"]}>
        <Button
          textColor={"white"}
          size="lg"
          bg={"#D64E1C"}
          w={["full", null, "unset"]}
          onClick={handleScrollToContactUs}
          _hover={{ bg: "#FF5F1F" }}
        >
          JOIN THE WAITLIST
        </Button>
      </Box>
    </VStack>
  );
};
export default HeroSection;
