import { VStack, Text } from "@chakra-ui/react";

const Footer = () => {
  return (
    <VStack py={4} bg={"#161616"} spacing={8}>
      <Text color={"white"} fontWeight={"bold"}>
        Made with ❤️in INDIA
      </Text>
      <Text color={"grey"} fontSize={"sm"}>
        Copyright © 2024 QuiveClub - All Rights Reserved.
      </Text>
    </VStack>
  );
};
export default Footer;
